// Import our CSS
import "@/scss/app.scss";

/**
 * Alpine JS
 * Best to put it last so that all other potential JS is available
 * when components start getting initialized.
 */
import AOS from "aos";
import Alpine from "alpinejs";
import collapse from "@alpinejs/collapse";

Alpine.plugin(collapse);

// Internal
// import { menu } from "./components/menu.js";
// import { stickyHeader } from "./components/stickyheader.js";

window.addEventListener("load", (event) => {
    AOS.init({
        once: "true",
    });
});

// JS callback functions for:
// 1. Hamburger Menu toggle.
// 2. Show / Hide Mobile Menu (on hamburger button click)
// menu();

// To make desktop header sticky on scroll.
// stickyHeader();

window.Alpine = Alpine;
Alpine.plugin(collapse);
Alpine.start();
